import React from 'react';
import * as Yup from 'yup';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { Formik } from 'formik';
import { PulseLoader } from 'react-spinners';

import { useUserAuth } from 'contexts/UserAuth';
import { useFirebaseAuth } from 'contexts/FirebaseAuth';
import useRWD from 'hooks/useRWD';

import api from 'utils/api';

const { useState, useEffect } = React;

const RatingModal = (props) => {
  const { isLg } = useRWD();
  const { currentUser } = useFirebaseAuth();
  const { user, getAuthorizationHeader } = useUserAuth();

  const [isOpen, setIsOpen] = React.useState(false);

  const [rating, setRating] = useState(0);
  // const [lastLoginAt, setLastLoginAt] = useState(0);
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const { metadata } = currentUser;
      // const { lastLoginAt: timestamp } = metadata;
      // setLastLoginAt(timestamp);
    }
  }, [currentUser]);

  useEffect(() => {
    if (user && user.post_signup_questionnaire_filled) {
      const lastSurveyAt = user.last_survey_at || 0;
      const now = Date.now();
      // console.log({ lastSurveyAt, now });
      if ((now - lastSurveyAt) / 1000 > 60 * 60 * 24 * 90) {
        if (!localStorage.getItem('sorra_survey_popup')) {
          setCountDown(10);
          // console.log('do countdown');
        } else {
          // console.log('do not countdown');
        }
      } else {
        // console.log('do not countdown');
      }
    }
  }, [user]);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        if (countDown === 1) {
          if (!localStorage.getItem('sorra_survey_popup')) {
            setIsOpen(true);
            localStorage.setItem('sorra_survey_popup', 'true');
          }
        }
        setCountDown((prev) => {
          return prev - 1;
        });
      }, 1000);
    }
  }, [countDown]);

  return user && isOpen ? (
    <div
      className="fixed bottom-0 left-0 right-7 z-[99] w-full rounded-t-lg bg-white pb-safe lg:bottom-7 lg:left-auto lg:w-[480px] lg:rounded-lg"
      style={{
        filter: isLg
          ? 'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.10))'
          : 'drop-shadow(0px -4px 50px rgba(0, 0, 0, 0.10))',
      }}
    >
      <div className="flex items-center justify-between px-4 pt-4">
        <button
          className="rounded-lg border border-gray-100 p-2.5"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <XMarkIcon className="size-5 text-[#111928]" />
        </button>
        <p className="text-base font-medium text-[#111928]">
          你在sorra.net的體驗如何呢？
        </p>
        <div className="w-[42px]" />
      </div>
      <div className="flex items-center justify-around py-5">
        {[1, 2, 3, 4, 5].map((r) => {
          const highlight = rating >= r;

          return (
            <button
              key={r}
              className="h-[26px] w-[26px]"
              onClick={() => {
                if (rating === r) {
                  setRating((prev) => {
                    return prev - 1;
                  });
                  return;
                }
                setRating(r);
              }}
            >
              <img
                src={`/images/icons/${highlight ? 'heart-pink' : 'heart-gray'}.png`}
                className="h-full w-full object-cover"
              />
            </button>
          );
        })}
      </div>
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={{
          opinion: '',
        }}
        validationSchema={Yup.object({
          // opinion: Yup.string().required('Required'),
        })}
        onSubmit={async (values, actions) => {
          try {
            const headers = await getAuthorizationHeader();
            const { data } = await api.request({
              url: `/api/v3/platform/survey`,
              method: 'POST',
              data: {
                rating: rating,
                opinion: values.opinion,
              },
              headers,
            });
          } catch (error) {
            console.log({ error });
          }
          setIsOpen(false);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
          } = props;
          return (
            <form className="flex w-full flex-col px-4" onSubmit={handleSubmit}>
              <div>
                <textarea
                  type="text"
                  name="opinion"
                  className="h-[120px] w-full border border-gray-300 px-5 py-3.5 font-normal placeholder:text-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent"
                  placeholder="更多意見及聯絡方法"
                  value={values.opinion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <button
                type="submit"
                className="my-6 flex flex-1 items-center justify-center rounded-lg bg-sorra-pink py-3 text-base font-medium text-white disabled:opacity-50"
                disabled={!isValid || isSubmitting || rating === 0}
              >
                {isSubmitting ? (
                  <PulseLoader color="#FFFFFF" size={12} />
                ) : (
                  '確認'
                )}
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  ) : null;
};

export default RatingModal;
