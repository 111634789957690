import React from "react";
import { useMediaQuery } from 'react-responsive'

const useRWD = () => {
    // @media (min-width: 1024) { ... }
    const isLg = useMediaQuery({ minWidth: 1024 })
    return {
        isLg
    }
};

export default useRWD;