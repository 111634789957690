const title =
  '護膚品好用推介/比較 | 化妝品推薦香港 | 最真實化妝護膚品評價 - Sorra';
const description =
  '尋找護膚品好用的推薦和比較？Sorra提供最詳實的產品評論、價格比較、成份分析和用戶意見。我們根據用戶評價和專業評測，比較各種化妝品和護膚品，幫助您做出明智的選擇。無論您需要什麼，從化妝品到護膚品，我們的平台都有您需要的資訊。立即探索，找到最適合您的產品！';

const nextSeoConfig = {
  title,
  description,
  canonical: 'https://www.sorra.net/',
  languageAlternates: [
    {
      hrefLang: 'zh-HK',
      href: 'https://www.sorra.net/',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'icon',
      href: 'https://sorra.azureedge.net/assets/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: '/images/appleTouchIcon.png',
    },
  ],
  additionalMetaTags: [
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1.0, maximum-scale=1, viewport-fit=cover',
    },
    {
      name: 'apple-mobile-web-app-title',
      content: 'Sorra',
    },
  ],
  openGraph: {
    title,
    description,
    type: 'website',
    url: 'https://www.sorra.net/',
    // images: [
    //   {
    //     url: '/snsThumbnail.jpg',
    //     width: 800,
    //     height: 600,
    //     alt: 'Sorra',
    //   },
    // ],
    siteName: 'Sorra',
  },
};

export default nextSeoConfig;
