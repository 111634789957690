import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
mixpanel.init(mixpanelToken, {
  track_pageview: 'url-with-path',
  persistence: 'localStorage',
  debug: false,
});

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let MixpanelConfig = actions;

export default mixpanel;
