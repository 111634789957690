import React from 'react';

const { createContext, useContext, useState, useEffect } = React;

export const GlobalConfigContext = createContext({});
GlobalConfigContext.displayName = 'GlobalConfig';

export const GlobalConfigProvider = (props) => {
  const { children, fonts } = props;
  return (
    <GlobalConfigContext.Provider value={{ fonts }}>
      {children}
    </GlobalConfigContext.Provider>
  );
};

export const useGlobalConfig = () => {
  return useContext(GlobalConfigContext);
};
