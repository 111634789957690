import React from 'react';
import { useFirebaseAuth } from 'contexts/FirebaseAuth';
import api from 'utils/api';
import mixpanel from 'utils/mixpanelConfig';

const { createContext, useContext, useState, useEffect } = React;

export const UserAuthContext = createContext({});
UserAuthContext.displayName = 'UserAuth';

export const UserAuthProvider = (props) => {
  const { children } = props;
  const { currentUser } = useFirebaseAuth();

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const getAuthorizationHeader = async () => {
    if (currentUser) {
      try {
        const idToken = await currentUser.getIdToken();
        setToken(idToken);
        return { Authorization: `Bearer ${idToken}` };
      } catch (error) {}
    }
    return {};
  };

  const fetchUser = async () => {
    if (currentUser) {
      const headers = await getAuthorizationHeader();
      try {
        const { data } = await api.request({
          url: '/api/v2/me',
          headers,
        });
        setUser(data);
        mixpanel.identify(data.id);
        mixpanel.people.set({});
      } catch (error) {
        setUser(null);
      }
    } else {
      setUser(null);
      setToken(null);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [currentUser]);

  return (
    <UserAuthContext.Provider
      value={{ user, token, fetchUser, getAuthorizationHeader }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => {
  return useContext(UserAuthContext);
};
