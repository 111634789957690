import _ from 'lodash';
import Axios from 'axios';

const url = process.env.NEXT_PUBLIC_API_HOST;
const api = Axios.create({
  baseURL: url,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const swrFetcher = async (url, token) => {
  let headers = {};
  if (_.isFunction(token)) {
    headers = await token();
  } else if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return api.get(url, { headers }).then((res) => {
    return res.data;
  });
};

export default api;
